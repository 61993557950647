<template>
  <div>
    <div
      class="px-1"
      v-for="(notification, index) in notificationData"
      :key="index"
    >
      <a
        v-if="notification.type === 'Xero_Token_Expired'"
        target="_blank"
        :href="'/grypas-api' + notification.url"
      >
        <div class="row py-1">
          <div class="col-2 text-center">
            <b-avatar
              v-if="notification.user.avatar"
              size="40"
              :src="
                notification.user.avatar &&
                notification.user.avatar.includes('https://ui-avatars.com')
                  ? notification.user.avatar
                  : $store.state.appConfig.imageBaseUrl +
                    notification.user.avatar
              "
              variant="light-primary"
              class="badge-minimal"
            >
              <feather-icon
                v-if="!notification.avatar"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
          </div>
          <div class="col-10 py-0 my-0">
            <p class="media-heading d-flex flex-row justify-content-between">
              <span class="font-weight-bolder">
                {{ notification.title }}
              </span>
            </p>
            <div class="d-flex justify-content-between">
              <span class="text-muted time-date-text"
                >{{ getFormattedDate(notification.created_at) }}
              </span>
            </div>
          </div>
        </div>
      </a>
      <div
        v-if="customTypes.includes(notification.type)"
        class="row py-1 custom-notification"
      >
        <div class="col-2 text-center">
          <b-avatar
            v-if="notification.user.avatar"
            size="40"
            :src="
              notification.user.avatar &&
              notification.user.avatar.includes('https://ui-avatars.com')
                ? notification.user.avatar
                : $store.state.appConfig.imageBaseUrl + notification.user.avatar
            "
            variant="light-primary"
            class="badge-minimal"
          >
            <feather-icon
              v-if="!notification.avatar"
              icon="UserIcon"
              size="22"
            />
          </b-avatar>
        </div>
        <div class="col-10 py-0 my-0">
          <p class="media-heading d-flex flex-row justify-content-between">
            <span class="font-weight-bolder title">
              {{ notification.title }}
            </span>
          </p>
          <div class="d-flex justify-content-between">
            <span class="text-muted time-date-text"
              >{{ getFormattedDate(notification.created_at) }}
            </span>
            <span v-if="notification.message.status === 0">
              <button
                @click="getPreviewFile(notification)"
                class="btn btn-sm btn-success"
              >
                &check;
              </button>
            </span>
          </div>
        </div>
      </div>
      <b-link
        v-else
        :to="getRedirectUrl(notification.type, notification.message)"
      >
        <div class="row py-1">
          <div class="col-2 text-center">
            <b-avatar
              v-if="notification.user.avatar"
              size="40"
              :src="
                notification.user.avatar &&
                notification.user.avatar.includes('https://ui-avatars.com')
                  ? notification.user.avatar
                  : $store.state.appConfig.imageBaseUrl +
                    notification.user.avatar
              "
              variant="light-primary"
              class="badge-minimal"
            >
              <feather-icon
                v-if="!notification.avatar"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
          </div>
          <div class="col-10 py-0 my-0">
            <p class="media-heading d-flex flex-row justify-content-between">
              <span class="font-weight-bolder">
                {{ notification.title }}
              </span>
            </p>
            <div class="d-flex justify-content-between">
              <span class="text-muted time-date-text"
                >{{ getFormattedDate(notification.created_at) }}
              </span>
            </div>
          </div>
        </div>
      </b-link>
      <hr />
    </div>
    <b-modal
      v-model="showPreviewModal"
      size="lg"
      @ok="approve(fileDetails)"
      ok-title="Approve"
    >
      <div class="row">
        <div class="col-12 text-center">
          <!-- if filetype is image then show image tag -->
          <img
            v-if="
              fileDetails.file_ext == 'jpg' ||
              fileDetails.file_ext == 'png' ||
              fileDetails.file_ext == 'jpeg'
            "
            :src="previewFile(fileDetails.file)"
            class="img-fluid"
            height="400px"
            width="700px"
            alt="document file"
          />
          <!-- if filetype is pdf then show pdf tag -->
          <embed
            v-if="fileDetails.file_ext == 'pdf'"
            :src="previewFile(fileDetails.file)"
            type="application/pdf"
            height="400px"
            width="700px"
            alt="document file"
          />
          <!-- if filetype is docx then show docx tag -->

          <iframe
            v-if="fileDetails.file_ext == 'docx'"
            :src="previewFile(fileDetails.file)"
            height="400px"
            width="700px"
            alt="document file"
          ></iframe>
        </div>
        <div class="col-12 col-md-6 mt-2" v-if="fileDetails.name">
          <b-form-group>
            <label class="label-case data-text" for=""
              >{{ fileDetails.type }} name</label
            >
            <p class="px-1 m-0 border bg-light textbox-field">
              {{ fileDetails.name }}
            </p>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6 mt-2" v-if="fileDetails.location">
          <b-form-group>
            <label class="label-case data-text" for=""
              >{{ fileDetails.type }} issued from</label
            >
            <p class="px-1 m-0 border bg-light textbox-field">
              {{ fileDetails.location }}
            </p>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group>
            <label class="label-case data-text" for=""
              >{{ fileDetails.type }} number</label
            >
            <p class="px-1 m-0 border bg-light textbox-field">
              {{ fileDetails.number }}
            </p>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <b-form-group>
            <label class="label-case data-text" for=""
              >{{ fileDetails.type }} expiry</label
            >
            <p class="px-1 m-0 border bg-light textbox-field">
              {{ fileDetails.validation }}
            </p>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group>
            <p class="textbox-field">
              This {{ fileDetails.type }} will be expired in
              {{ fileDetails.rem_days }}
              days
            </p>
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import {
  BAvatar,
  BCard,
  BFormGroup,
  BFormInput,
  BLink,
  BModal,
  BPagination,
  BTable,
} from "bootstrap-vue";
import { toast } from "@/utils/toast";
export default {
  props: {
    notificationData: {
      type: Array,
      default: [[]],
    },
  },
  components: {
    BAvatar,
    BCard,
    BFormGroup,
    BFormInput,
    BLink,
    BModal,
    BPagination,
    BTable,
  },
  mounted() {
    if (this.$route.name === "notifications") {
      this.getNotifications();
    }
  },
  data() {
    return {
      showPreviewModal: false,
      notifications: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      count: null,
      fileDetails: {},
      customTypes: ["certificate", "driving", "passport"],
      notificationArr: [
        {
          type: "superadmin",
          url: "/",
          isDynamic: false,
        },
        {
          type: "admin",
          url: "/",
          isDynamic: false,
        },
        {
          type: "customer",
          url: "/",
          isDynamic: false,
        },
        {
          type: "employee",
          url: "/",
          isDynamic: false,
        },

        {
          type: "employee-hire",
          url: "/employee-preview",
          isDynamic: true,
        },
        {
          type: "certificate",
          url: "/employee-preview",
          isDynamic: false,
        },
        {
          type: "passport",
          url: "/employee-preview",
          isDynamic: false,
        },
        {
          type: "driving",
          url: "/employee-preview",
          isDynamic: false,
        },
        {
          type: "certificate-expire",
          url: "/employee-preview",
          isDynamic: true,
        },
        {
          type: "certificate-remove",
          url: "/employee-preview",
          isDynamic: true,
        },
        {
          type: "certificate-approved",
          url: "/employee-preview",
          isDynamic: true,
        },
        {
          type: "driving-expire",
          url: "/employee-preview",
          isDynamic: true,
        },
        {
          type: "driving-approved",
          url: "/employee-preview",
          isDynamic: true,
        },
        {
          type: "passport-expire",
          url: "/employee-preview",
          isDynamic: true,
        },
        {
          type: "passport-approved",
          url: "/employee-preview",
          isDynamic: true,
        },
        {
          type: "visa-expire",
          url: "/employee-preview",
          isDynamic: true,
        },
        {
          type: "visa-approved",
          url: "/employee-preview",
          isDynamic: true,
        },
        {
          type: "job-apply",
          url: "/job-posts/applicants",
          isDynamic: true,
        },
        {
          type: "job-post",
          url: "/job-posts",
          isDynamic: false,
        },
        {
          type: "job-shortlist",
          url: "/job-post/preview",
          isDynamic: true,
        },
        {
          type: "roster-accept-decline",
          url: "/published-roster",
          isDynamic: false,
        },
        {
          type: "roster-approve",
          url: "/published-roster",
          isDynamic: false,
        },

        {
          type: "roster_declined",
          url: "/published-roster",
          isDynamic: false,
        },
        {
          type: "approve-declined",
          url: "/published-roster",
          isDynamic: false,
        },

        {
          type: "timesheet",
          url: "/time-sheets",
          isDynamic: false,
        },
        {
          type: "Suspended",
          url: "/",
          isDynamic: false,
        },
        {
          type: "Unsuspended",
          url: "/",
          isDynamic: false,
        },
        {
          type: "suspend",
          url: "/",
          isDynamic: false,
        },
        {
          type: "Xero_Token_Expired",
          url: "/grypas-api/success-callback",
          isDynamic: false,
        },
      ],
    };
  },
  methods: {
    getNotifications() {
      this.$store
        .dispatch("user/notification", this.userData?.id)
        .then((response) => {
          this.notifications = response.data.data;
          // assign notifications to the notificationData prop
        })
        .catch((error) => {});
    },
    getFormattedDate(date) {
      // dd/mm/yyyy hh:mm a
      return moment(date).format("DD/MM/YYYY hh:mm A");
    },
    getRedirectUrl(type, message) {
      let url = "";
      let isDynamic = false;
      this.notificationArr.forEach((item) => {
        // make lower case and compare
        if (item.type.toLowerCase() === type.toLowerCase()) {
          url = item.url;
          isDynamic = item.isDynamic;
        }
      });
      if (isDynamic) {
        url = url + "/" + message.subject_id;
      }
      return url;
    },
    getPreviewFile(notification) {
      this.showPreviewModal = true;
      let fileDetails = {
        type: notification.type,
        name:
          notification.type === "certificate"
            ? notification.message?.certificate_name
            : null,
        number:
          notification.type === "driving"
            ? notification.message?.license_number
            : notification.type === "certificate"
            ? notification.message?.certificate_number
            : notification.type === "passport"
            ? notification.message?.passport_number
            : null,
        rem_days:
          notification.type === "driving"
            ? notification.message?.driving_rem_days
            : notification.type === "certificate"
            ? notification.message?.certificate_rem_days
            : notification.type === "passport"
            ? notification.message?.passport_rem_days
            : null,
        validation:
          notification.type === "driving"
            ? notification.message?.license_validation
            : notification.type === "certificate"
            ? notification.message?.certificate_validation
            : notification.type === "passport"
            ? notification.message?.passport_validation
            : null,
        file:
          notification.type === "driving"
            ? notification.message?.license_file
            : notification.type === "certificate"
            ? notification.message?.certificate_file
            : notification.type === "passport"
            ? notification.message?.passport_file
            : null,
        file_ext:
          notification.type === "driving"
            ? notification.message?.license_file.split(".").pop()
            : notification.type === "certificate"
            ? notification.message?.certificate_file.split(".").pop()
            : notification.type === "passport"
            ? notification.message?.passport_file.split(".").pop()
            : null,
        location:
          notification.type === "driving"
            ? notification.message?.issuing_state
            : notification.type === "certificate"
            ? null
            : notification.type === "passport"
            ? notification.message?.country
            : null,
        employee_id: notification.message?.employee_id,
        certificate_id:
          notification.type === "certificate" ? notification.message?.id : null,
        notification_id: notification.id,
        status: notification.message?.status,
      };
      this.fileDetails = fileDetails;
    },
    previewFile(file) {
      return this.$store.state.appConfig.imageBaseUrl + file;
    },
    approve(item) {
      console.log(item, "approve");
      this.$store
        .dispatch("employee/approveDocument", {
          employee_id: item.employee_id,
          type: item.type,
          certificate_id: item.certificate_id,
          notification_id: item.notification_id,
        })
        .then((response) => {
          if (response.status === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Document Approved "
            );
          } else {
            toast("Error", "AlertTriangleIcon", "danger", "Error Approvation");
          }
        })
        .catch((error) => {
          console.log(error.response);
          toast("Error", "AlertTriangleIcon", "danger", "Error Approvation");
        });
    },
  },
};
</script>

<style scoped>
.time-date-text {
  font-size: 15px;
  text-align: end;
}
.label-case {
  text-transform: capitalize;
}
.custom-notification {
  cursor: pointer;
}
.custom-notification .title {
  color: #7367f0;
}
.label-case {
  text-transform: capitalize;
}
.textbox-field {
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.row-height {
  height: 105px;
}
</style>
