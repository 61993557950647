<!-- eslint-disable quotes -->
<!-- eslint-disable quotes -->
<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p v-if="userData" class="user-name font-weight-bolder mb-0">
          {{ userData.name }}
        </p>
        <span class="user-status" v-if="userData && userData.roles.length > 0">
          <span v-if="userData.roles[0].name =='customer'"> Company</span>
          <span v-else> {{ userData.roles[0].name }}</span>
         
        </span>
      </div>
      <b-avatar
        v-if="userData && userData.name"
        size="40"
        :src="
          userData.avatar && userData.avatar.includes('https://ui-avatars.com')
            ? userData.avatar
            : $store.state.appConfig.imageBaseUrl + userData.avatar
        "
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile {{ userData.roles[0].name }}</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      v-if="userData.roles[0].name === 'admin'"
      :to="`/company/view/${userData.id}`"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="userData.roles[0].name === 'customer'"
      :to="`/customer/view/${userData.id}`"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="userData.roles[0].name === 'employee'"
      :to="`/employee-preview/${userData.id}`"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import { isUserLoggedIn } from "@/auth/utils";
import { initialAbility } from "@/libs/acl/config";
import { avatarText } from "@core/utils/filter";
import { BAvatar, BDropdownItem, BNavItemDropdown } from "bootstrap-vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  beforeCreate() {
    if (!isUserLoggedIn()) {
      this.$router.push({ name: "auth-login" });
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>
